import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import "./assets/css/common.css";
import "./index.css";
import Route from "./route";
import "bootstrap/dist/css/bootstrap.min.css";
import "./comman/index.css";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <Router>
      <Toaster
        position="bottom-center"
        gutter={8}
        toastOptions={{
          duration: 5000,
        }}
      />
      <Route />
    </Router>
  );
}

export default App;
